@keyframes loadArticle{
    from {
        opacity: 0;
    }    

    to {
        opacity: 1;
    }
}

@keyframes revealButton {
    0% {
        opacity: 0;
        transform: scale(.5);
        filter: brightness(1);
    }
    75% {
        opactity: 1;
        transform: scale(1.2);
        filter: brightness(1.5);
    }
    100% {
        transform: scale(1);
    }
}

article {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    width: 100%;

    animation-name: loadArticle;
    animation-duration: 1s;
}

.article-image-handler img {
    border: none;
}

#date {
    margin-top: 15px;
    font-size: 16px;
    opacity: .8;
}

#topper {
    text-align: center;
    max-width: 500px;
    margin-bottom: 15px;
}

#topper h3 {
    margin: 0 auto;
}

#top-wrapper {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--layer0);
    padding: 10px;
    border-bottom-left-radius: 20px;
}

#body {
    max-width: 550px;
    text-align: left;
    white-space: pre-wrap;
   
    padding-bottom: 30px;
}

#body > p {
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;    
    transition: .25s;
    text-indent: 2em;
}

.wait {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

#comment-section {
    border-top: var(--text-color-half) dashed 3px;
    width: 500px;
}

#tools {
    cursor: pointer;
    position: sticky;
    right: 0;
    bottom: 10px;
    width: 35px;
    text-align: right;
    flex: none;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-name: revealButton;
    animation-duration: .15s;
    z-index:10;
}

#tools figure {
    background: var(--layer0);
    border: var(--text-color-half) solid 2px;
    border-radius: 50%;
    width: 100%;
    padding: 5px;
}

#tools figure:not(:last-child) {
    margin-bottom: 10px;
}

#tools img {
    display: block;
    width: 100%;
    height: 100%;
    /* filter: hue-rotate(180deg);
    filter: brightness(102); */
}

/* #jumpUp:hover {
    transform: scale(1.1);
    filter:brightness(1.2);
} */

#comment-section h3 {
    padding: 15px 0;
}

article img {
    margin: 0 auto;
    max-width: 100%;
    display: block;
    border: var(--text-color) solid 2px;
}

@media only screen and (max-width: 585px) {
    #body {
        line-height: 1.4em;
    }

    #body p {
        font-size: 16px;
    }

    #comment-section {
        width: 100%;
    }

    article img {
        width: 90%;
    }

    #tools {
        width: 40px;
        height: 40px;
        box-shadow: 0 5px 0 black;
    }
}

