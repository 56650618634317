@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.post-preview {
    padding: 25px 5px;
    position: relative;   
    animation-name: fadein;
    animation-duration: .15s;
}

.post-preview > * {
    transition: .15s;
}

.post-preview:not(:first-child) {
    border-top: var(--text-color-half) dashed 2px;
}

.date {
    /* position: absolute;
    right: 0;
    bottom: 5px; */
    font-style: italic;
    font-size: 18px;
    padding: 5px 0;
    opacity: .5;
}

.body {
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.2em;
    padding-bottom: 25px;
}

@media only screen and (max-width: 600px) {
    .post-preview h3 {
        font-size: 20px;
    }

    .post-preview .date {
        font-size: 16px;
    }
}