@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
/* ol, ul {
	list-style: none;
} */
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
header {
  /* position: sticky; */
  /* top: 0; */
  color: var(--alt-color);
  padding:0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  background: var(--background-color);
  z-index: 10;
}

#logo {
    padding-right: 15px;
    font-size: 24px;
}

#login-prompt {
  cursor: pointer;
  font-size: 16px;
  padding: 4px 0;
  border-bottom: var(--alt-color) solid 2px;
}

@media only screen and (max-width: 585px) {
  header {
    width: 100%;
    justify-content: space-between;
  }


}

@-webkit-keyframes revealUsername {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes revealUsername {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes revealFailure {
    from {
        opacity: 0;
        height: 0;
    }
    to {
        opacity: 1;
        height: auto;
        height: initial;
    }
}

@keyframes revealFailure {
    from {
        opacity: 0;
        height: 0;
    }
    to {
        opacity: 1;
        height: auto;
        height: initial;
    }
}
#login-modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: rgba(0,0,0,.45);   
}

input[placeholder=Username] {
    -webkit-animation-name: revealUsername;
            animation-name: revealUsername;
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
}

#login-buttons {
    margin-top: 15px;
    display: block;
}

#login-buttons button {
    background: var(--background-color);
    color: var(--text-color);
    border: var(--layer2) solid 2px;
    font-size: 16px;
    padding: 5px 8px;
}

#login-buttons :first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-right: 5px;
}

#login-buttons :last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

#login-box {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align:center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: black;
    padding: 15px;
    border: var(--comp-color-1) solid 4px;
    border-radius: 15px;
    z-index: 5;
}

#login-box input {
    display: block;
    flex: 1 1;
    padding: 7px;
    border: none;
    background: var(--background-color);
    color: var(--text-color);
    border: var(--layer2) solid 2px;
    margin: 8px 0 0 0;
}

#close-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

#close-modal p {
    font-family:'Courier New', Courier, monospace;
    font-size: 28px;
}

#failure-box {
    max-width: 200px;
}

#failure-message {
    -webkit-animation-name: revealFailure;
            animation-name: revealFailure;
    -webkit-animation-duration: .25s;
            animation-duration: .25s;
    font-size: 16px;
}
nav {
    /* position: sticky; */
    /* top: 0; */
    display: flex;
    flex: 1 1;
    height: 100%;
    /* background: var(--comp-color-1); */
    justify-content: space-evenly;
    align-items: center;
    margin-right: 15px;

    -webkit-animation-name: revealFailure;

            animation-name: revealFailure;
    -webkit-animation-duration: .3s;
            animation-duration: .3s;
}

nav > * {
    cursor: pointer;
    flex: 1 1;
    padding: 5px;
    text-align: center;
    /* margin: 0 5px; */
}

#active {
    font-weight: bolder;
    font-style: italic;
    font-size: 18px;
    /* text-decoration: underline; */
    /* border-bottom: var(--text-color) solid 2px; */
}

.navbutton {
    position: relative;
    font-size: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media only screen and (max-width: 700px) {
    .navbutton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 585px) {
    nav {
        margin-right: 0;
        margin-right: initial;
        flex: none;
        /* top: 50px; */
        /* background: var(--background-color-half); */
        /* z-index: 10; */
        padding: 10px;
    }

    .navbutton {
        display: inline-block;
    }
}

@-webkit-keyframes slide {
    from {
        /* opacity: 0; */
        max-height: 0;
        padding: 0;
        overflow-y: hidden;
    }
    to {
        padding: 5px;
        /* opacity: 1; */
        max-height: 200px;
        overflow-y: auto;
    }
}

@keyframes slide {
    from {
        /* opacity: 0; */
        max-height: 0;
        padding: 0;
        overflow-y: hidden;
    }
    to {
        padding: 5px;
        /* opacity: 1; */
        max-height: 200px;
        overflow-y: auto;
    }
}

#dropdown {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--comp-color-1);
    padding: 5px;
    -webkit-animation-name: slide;
            animation-name: slide;
    -webkit-animation-duration: .15s;
            animation-duration: .15s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    max-height: 200px;
    z-index: 5;
    overflow-y: auto;
}

#dropdown > * {
    display: block;
    padding: 5px;
    -webkit-animation-name: slide;
            animation-name: slide;
    -webkit-animation-duration: .15s;
            animation-duration: .15s;
    margin-bottom: 5px;
    font-size: 16px;
}
footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--alt-color);
    padding: 5px 15px;
    background: var(--background-color);
}
footer *:not(:last-child) {
    padding-right: 15px;
}

footer p {
    font-size: 16px;
}

.icon {
    width: 20px;
    border: none;
}
main {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content:center;
    background: var(--layer0);
}

main > div {
    padding: 10px;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    flex:1 1;
    flex-direction: column;
    align-items: center;
}
/* Generic Defaults */
* {
    box-sizing: border-box;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

ol, ul {
    padding-left: 3em;
    text-indent: 1em;
}

hr {
    border: none;
    border-bottom: var(--comp-color-1) dashed 3px;
    width: 100%;
}

p, a {
    font-size: 20px;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
}

strong {
    font-weight: bolder;
}

em {
    font-style: italic;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgb(45,45,45);
    background: var(--scrollbar-color);
}

::-webkit-scrollbar-thumb {
    background: rgb(165,165,165);
    background: var(--scrollbar-thumb);
}

html {
    background: black;
}


/* Imports */

.dark-mode {
    --background-color: rgb(11, 11, 12);    
    --background-color-half: rgba(11, 11, 12, 0.847);
    --navcolor: 46,46,54;
    --layer0: rgb(19, 19, 22);
    --layer1: rgb(31, 32, 36);
    --layer2: rgb(46, 46, 54);
    --layer3: rgb(67, 69, 75);
    --text-color: rgb(230,230,230);
    --text-color-half: rgba(230,230,230,.25);
    --comp-color-1: rgb(48, 114, 86);
    --comp-color-2: rgb(139, 76, 61);
}

.light-mode {
    --background-color: #ad605e;    
    --background-color-half: #f3f3f38c;
    --layer0: #e9e9e9;
    --layer1: #ad605e;
    --layer2: #cfc58c;
    --text-color: rgb(36, 36, 36);
    --text-color-half: rgba(36, 36, 36, 0.288);
    --comp-color-1: #86b3e6;
    --comp-color-2: #8b4c3d;
    --alt-color: white;

}

/* CSS Variables */
:root {
    --scrollbar-color: rgb(45,45,45);
    --scrollbar-thumb: rgb(165,165,165);
}

/* Custom Element Styling, Less Generic */

#root {
    
    font-family: 'Lato', sans-serif;
    /* font-family: 'Noto Sans JP', sans-serif; */

    /* font-family: "Hind", sans-serif; */
    /* font-family: 'Teko', sans-serif; */

    /* font-family: sans-serif; */
    background: var(--background-color);

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: hidden;
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    /* overflow-y: scroll; */
}

#content {
    background: var(--background-color);
    color: var(--text-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.darkbutton {
    background: var(--background-color);
    border-radius: 20px;
    border: var(--text-color) solid 2px;
    color: var(--text-color);
    font-size: 14px;
}
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.post-preview {
    padding: 25px 5px;
    position: relative;   
    -webkit-animation-name: fadein;   
            animation-name: fadein;
    -webkit-animation-duration: .15s;
            animation-duration: .15s;
}

.post-preview > * {
    transition: .15s;
}

.post-preview:not(:first-child) {
    border-top: var(--text-color-half) dashed 2px;
}

.date {
    /* position: absolute;
    right: 0;
    bottom: 5px; */
    font-style: italic;
    font-size: 18px;
    padding: 5px 0;
    opacity: .5;
}

.body {
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.2em;
    padding-bottom: 25px;
}

@media only screen and (max-width: 600px) {
    .post-preview h3 {
        font-size: 20px;
    }

    .post-preview .date {
        font-size: 16px;
    }
}
@-webkit-keyframes fadeoutAside {
    from {
        opacity: 1;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeoutAside {
    from {
        opacity: 1;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes fadeinAside {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeinAside {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

aside {
   
    padding: 10px;
    background: var(--layer1);
    color: var(--alt-color);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: .15s;

    -webkit-animation-name: fadeinAside;

            animation-name: fadeinAside;
    -webkit-animation-duration: .15s;
            animation-duration: .15s;
}

#info {
    flex: 1 1;
    line-height: 1.3em;
}

#info p{
    font-size: 16px;
}

#icon-box {
    flex: 1 1;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
    aside {
        -webkit-animation-name: fadeoutAside;
                animation-name: fadeoutAside;
        -webkit-animation-duration: .15s;
                animation-duration: .15s;
        position: absolute;
        visibility: hidden;
        flex: none;
    }
}
#frontpage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
}

#posts {
    display: flex;
    flex-direction: column;
    flex: 3 1;
    margin-right: 30px;
}

#full-logo-wrapper {
    align-self: center;
    width: 200px;
}

#full-logo {
    width: 100%;
}

#see-more-posts {
    font-size: 18px;
    padding: 5px;
    background: var(--layer2);
    border: none;

    color: var(--text-color);

}

#spacer {
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
    /* bottom: 70px; */
    flex: 1 1;
    transition: .15s;
}

@media only screen and (max-width: 800px) {
    #spacer {
        flex: 2 1;
    }
}

@media only screen and (max-width: 600px) {
    #spacer {
        display: none;
    }
    #posts {
        margin-right: 0;
        flex: 1 1;
    }
}

/*Media Query for short height*/
@media only screen and (max-height: 450px) {
    #spacer {
        display: none;
    }
}
#commentbox {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    font-family: sans-serif;

    border: none;
    padding: 5px;
}

#commentbox textarea {
    color: var(--text-color);
    background: var(--background-color);
    height: 8em;
    resize: vertical;
}

#commentbox button {
    width: 100px;
}

@media only screen and (max-width: 500px) {
    #commentbox {
        width: 100%;
    }
}
.comment {
    position: relative;
    margin-bottom: 15px;
    padding: 10px;
    background: var(--layer1);
    width: 100%;
}

.author {
    font-size: 16px;
    opacity: .75;
}

.comment-date {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 14px;
    opacity: .75;
}

.comment-body {
    font-size: 16px;
}

@-webkit-keyframes loadArticle{
    from {
        opacity: 0;
    }    

    to {
        opacity: 1;
    }
}

@keyframes loadArticle{
    from {
        opacity: 0;
    }    

    to {
        opacity: 1;
    }
}

@-webkit-keyframes revealButton {
    0% {
        opacity: 0;
        transform: scale(.5);
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }
    75% {
        opactity: 1;
        transform: scale(1.2);
        -webkit-filter: brightness(1.5);
                filter: brightness(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes revealButton {
    0% {
        opacity: 0;
        transform: scale(.5);
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }
    75% {
        opactity: 1;
        transform: scale(1.2);
        -webkit-filter: brightness(1.5);
                filter: brightness(1.5);
    }
    100% {
        transform: scale(1);
    }
}

article {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    width: 100%;

    -webkit-animation-name: loadArticle;

            animation-name: loadArticle;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

.article-image-handler img {
    border: none;
}

#date {
    margin-top: 15px;
    font-size: 16px;
    opacity: .8;
}

#topper {
    text-align: center;
    max-width: 500px;
    margin-bottom: 15px;
}

#topper h3 {
    margin: 0 auto;
}

#top-wrapper {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--layer0);
    padding: 10px;
    border-bottom-left-radius: 20px;
}

#body {
    max-width: 550px;
    text-align: left;
    white-space: pre-wrap;
   
    padding-bottom: 30px;
}

#body > p {
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;    
    transition: .25s;
    text-indent: 2em;
}

.wait {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

#comment-section {
    border-top: var(--text-color-half) dashed 3px;
    width: 500px;
}

#tools {
    cursor: pointer;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    bottom: 10px;
    width: 35px;
    text-align: right;
    flex: none;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-animation-name: revealButton;
            animation-name: revealButton;
    -webkit-animation-duration: .15s;
            animation-duration: .15s;
    z-index:10;
}

#tools figure {
    background: var(--layer0);
    border: var(--text-color-half) solid 2px;
    border-radius: 50%;
    width: 100%;
    padding: 5px;
}

#tools figure:not(:last-child) {
    margin-bottom: 10px;
}

#tools img {
    display: block;
    width: 100%;
    height: 100%;
    /* filter: hue-rotate(180deg);
    filter: brightness(102); */
}

/* #jumpUp:hover {
    transform: scale(1.1);
    filter:brightness(1.2);
} */

#comment-section h3 {
    padding: 15px 0;
}

article img {
    margin: 0 auto;
    max-width: 100%;
    display: block;
    border: var(--text-color) solid 2px;
}

@media only screen and (max-width: 585px) {
    #body {
        line-height: 1.4em;
    }

    #body p {
        font-size: 16px;
    }

    #comment-section {
        width: 100%;
    }

    article img {
        width: 90%;
    }

    #tools {
        width: 40px;
        height: 40px;
        box-shadow: 0 5px 0 black;
    }
}


@-webkit-keyframes articlePopup {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes articlePopup {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#article-list-wrapper {
    width: 500px;
    flex: 1 1;
    align-self: center;
}

#article-list {
    width: 100%;
    flex: 1 1;
    overflow-y: auto;
}

#pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#pagination img{
    width: 20px;
    cursor: pointer;
}

#arrows {
    display: flex;
    justify-content: center;
}

#backpage {
    margin-right: 15px;
    transform: rotateZ(180deg);
}

#pagination p {
    font-size: 14px;
    flex: 1 1;
}

input[name=search] {
    /* max-width: 200px; */
    flex: 1 1;
    background: var(--background-color);
    border: none;
    border-bottom: var(--text-color-half) solid 2px;
    padding: 8px 15px;
    color: var(--text-color);
}

.deactivated {
    display: none;
}

.article-link {
    cursor: pointer;
    padding: 15px;
    transition: .15s;
    max-width: 500px;
    display: block;

    -webkit-animation-name: articlePopup;

            animation-name: articlePopup;
    -webkit-animation-duration: .8s;
            animation-duration: .8s;
}

/* .article-link delays (inelegant, but functional) */

/* #article-list :nth-child(1) {
    animation-delay: 0s;
} */
/* #article-list :nth-child(2) {
    animation-delay: .05s;
}
#article-list :nth-child(3) {
    animation-delay: .1s;
}
#article-list :nth-child(4) {
    animation-delay: .15s;
}
#article-list :nth-child(5) {
    animation-delay: .20s;
} */

/* =/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/ */

.article-link .date {
    font-size: 16px;
}

.medium {
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 15px;
    border: var(--text-color-half) solid 1px;
    width: auto;
    width: initial;
    display: inline-block;
}

.article-link:hover {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
}

.medium[value = "video games"] {
    background: var(--comp-color-1);
}

#no-results {
    margin-top: 15px;
}

@media only screen and (max-width: 500px) {
    #article-list-wrapper {
        width: 100%;
    }
}
