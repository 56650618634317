@keyframes slide {
    from {
        /* opacity: 0; */
        max-height: 0;
        padding: 0;
        overflow-y: hidden;
    }
    to {
        padding: 5px;
        /* opacity: 1; */
        max-height: 200px;
        overflow-y: auto;
    }
}

#dropdown {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--comp-color-1);
    padding: 5px;
    animation-name: slide;
    animation-duration: .15s;
    animation-timing-function: ease-in;
    max-height: 200px;
    z-index: 5;
    overflow-y: auto;
}

#dropdown > * {
    display: block;
    padding: 5px;
    animation-name: slide;
    animation-duration: .15s;
    margin-bottom: 5px;
    font-size: 16px;
}