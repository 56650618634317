header {
  /* position: sticky; */
  /* top: 0; */
  color: var(--alt-color);
  padding:0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  background: var(--background-color);
  z-index: 10;
}

#logo {
    padding-right: 15px;
    font-size: 24px;
}

#login-prompt {
  cursor: pointer;
  font-size: 16px;
  padding: 4px 0;
  border-bottom: var(--alt-color) solid 2px;
}

@media only screen and (max-width: 585px) {
  header {
    width: 100%;
    justify-content: space-between;
  }


}
