footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--alt-color);
    padding: 5px 15px;
    background: var(--background-color);
}
footer *:not(:last-child) {
    padding-right: 15px;
}

footer p {
    font-size: 16px;
}

.icon {
    width: 20px;
    border: none;
}