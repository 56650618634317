@keyframes revealUsername {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes revealFailure {
    from {
        opacity: 0;
        height: 0;
    }
    to {
        opacity: 1;
        height: initial;
    }
}
#login-modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: rgba(0,0,0,.45);   
}

input[placeholder=Username] {
    animation-name: revealUsername;
    animation-duration: .5s;
}

#login-buttons {
    margin-top: 15px;
    display: block;
}

#login-buttons button {
    background: var(--background-color);
    color: var(--text-color);
    border: var(--layer2) solid 2px;
    font-size: 16px;
    padding: 5px 8px;
}

#login-buttons :first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-right: 5px;
}

#login-buttons :last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

#login-box {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align:center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: black;
    padding: 15px;
    border: var(--comp-color-1) solid 4px;
    border-radius: 15px;
    z-index: 5;
}

#login-box input {
    display: block;
    flex: 1;
    padding: 7px;
    border: none;
    background: var(--background-color);
    color: var(--text-color);
    border: var(--layer2) solid 2px;
    margin: 8px 0 0 0;
}

#close-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

#close-modal p {
    font-family:'Courier New', Courier, monospace;
    font-size: 28px;
}

#failure-box {
    max-width: 200px;
}

#failure-message {
    animation-name: revealFailure;
    animation-duration: .25s;
    font-size: 16px;
}