nav {
    /* position: sticky; */
    /* top: 0; */
    display: flex;
    flex: 1;
    height: 100%;
    /* background: var(--comp-color-1); */
    justify-content: space-evenly;
    align-items: center;
    margin-right: 15px;

    animation-name: revealFailure;
    animation-duration: .3s;
}

nav > * {
    cursor: pointer;
    flex: 1;
    padding: 5px;
    text-align: center;
    /* margin: 0 5px; */
}

#active {
    font-weight: bolder;
    font-style: italic;
    font-size: 18px;
    /* text-decoration: underline; */
    /* border-bottom: var(--text-color) solid 2px; */
}

.navbutton {
    position: relative;
    font-size: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media only screen and (max-width: 700px) {
    .navbutton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 585px) {
    nav {
        margin-right: initial;
        flex: none;
        /* top: 50px; */
        /* background: var(--background-color-half); */
        /* z-index: 10; */
        padding: 10px;
    }

    .navbutton {
        display: inline-block;
    }
}
