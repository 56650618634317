@keyframes fadeoutAside {
    from {
        opacity: 1;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeinAside {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

aside {
   
    padding: 10px;
    background: var(--layer1);
    color: var(--alt-color);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: .15s;

    animation-name: fadeinAside;
    animation-duration: .15s;
}

#info {
    flex: 1;
    line-height: 1.3em;
}

#info p{
    font-size: 16px;
}

#icon-box {
    flex: 1;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
    aside {
        animation-name: fadeoutAside;
        animation-duration: .15s;
        position: absolute;
        visibility: hidden;
        flex: none;
    }
}