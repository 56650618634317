@keyframes articlePopup {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#article-list-wrapper {
    width: 500px;
    flex: 1;
    align-self: center;
}

#article-list {
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

#pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#pagination img{
    width: 20px;
    cursor: pointer;
}

#arrows {
    display: flex;
    justify-content: center;
}

#backpage {
    margin-right: 15px;
    transform: rotateZ(180deg);
}

#pagination p {
    font-size: 14px;
    flex: 1;
}

input[name=search] {
    /* max-width: 200px; */
    flex: 1;
    background: var(--background-color);
    border: none;
    border-bottom: var(--text-color-half) solid 2px;
    padding: 8px 15px;
    color: var(--text-color);
}

.deactivated {
    display: none;
}

.article-link {
    cursor: pointer;
    padding: 15px;
    transition: .15s;
    max-width: 500px;
    display: block;

    animation-name: articlePopup;
    animation-duration: .8s;
}

/* .article-link delays (inelegant, but functional) */

/* #article-list :nth-child(1) {
    animation-delay: 0s;
} */
/* #article-list :nth-child(2) {
    animation-delay: .05s;
}
#article-list :nth-child(3) {
    animation-delay: .1s;
}
#article-list :nth-child(4) {
    animation-delay: .15s;
}
#article-list :nth-child(5) {
    animation-delay: .20s;
} */

/* =/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/=/ */

.article-link .date {
    font-size: 16px;
}

.medium {
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 15px;
    border: var(--text-color-half) solid 1px;
    width: initial;
    display: inline-block;
}

.article-link:hover {
    filter: brightness(1.1);
}

.medium[value = "video games"] {
    background: var(--comp-color-1);
}

#no-results {
    margin-top: 15px;
}

@media only screen and (max-width: 500px) {
    #article-list-wrapper {
        width: 100%;
    }
}