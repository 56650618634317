/* Generic Defaults */
* {
    box-sizing: border-box;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

ol, ul {
    padding-left: 3em;
    text-indent: 1em;
}

hr {
    border: none;
    border-bottom: var(--comp-color-1) dashed 3px;
    width: 100%;
}

p, a {
    font-size: 20px;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
}

strong {
    font-weight: bolder;
}

em {
    font-style: italic;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: var(--scrollbar-color);
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
}

html {
    background: black;
}


/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap');

.dark-mode {
    --background-color: rgb(11, 11, 12);    
    --background-color-half: rgba(11, 11, 12, 0.847);
    --navcolor: 46,46,54;
    --layer0: rgb(19, 19, 22);
    --layer1: rgb(31, 32, 36);
    --layer2: rgb(46, 46, 54);
    --layer3: rgb(67, 69, 75);
    --text-color: rgb(230,230,230);
    --text-color-half: rgba(230,230,230,.25);
    --comp-color-1: rgb(48, 114, 86);
    --comp-color-2: rgb(139, 76, 61);
}

.light-mode {
    --background-color: #ad605e;    
    --background-color-half: #f3f3f38c;
    --layer0: #e9e9e9;
    --layer1: #ad605e;
    --layer2: #cfc58c;
    --text-color: rgb(36, 36, 36);
    --text-color-half: rgba(36, 36, 36, 0.288);
    --comp-color-1: #86b3e6;
    --comp-color-2: #8b4c3d;
    --alt-color: white;

}

/* CSS Variables */
:root {
    --scrollbar-color: rgb(45,45,45);
    --scrollbar-thumb: rgb(165,165,165);
}

/* Custom Element Styling, Less Generic */

#root {
    
    font-family: 'Lato', sans-serif;
    /* font-family: 'Noto Sans JP', sans-serif; */

    /* font-family: "Hind", sans-serif; */
    /* font-family: 'Teko', sans-serif; */

    /* font-family: sans-serif; */
    background: var(--background-color);

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: hidden;
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    /* overflow-y: scroll; */
}

#content {
    background: var(--background-color);
    color: var(--text-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.darkbutton {
    background: var(--background-color);
    border-radius: 20px;
    border: var(--text-color) solid 2px;
    color: var(--text-color);
    font-size: 14px;
}