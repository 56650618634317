#frontpage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
}

#posts {
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-right: 30px;
}

#full-logo-wrapper {
    align-self: center;
    width: 200px;
}

#full-logo {
    width: 100%;
}

#see-more-posts {
    font-size: 18px;
    padding: 5px;
    background: var(--layer2);
    border: none;

    color: var(--text-color);

}

#spacer {
    position: sticky;
    top: 15px;
    /* bottom: 70px; */
    flex: 1;
    transition: .15s;
}

@media only screen and (max-width: 800px) {
    #spacer {
        flex: 2;
    }
}

@media only screen and (max-width: 600px) {
    #spacer {
        display: none;
    }
    #posts {
        margin-right: 0;
        flex: 1;
    }
}

/*Media Query for short height*/
@media only screen and (max-height: 450px) {
    #spacer {
        display: none;
    }
}